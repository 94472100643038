import React from 'react';
import './HeaderArticleCTA.css';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const HeaderArticleCTA = ({ productUrl }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'dl_pass_article',
      },
    });

    navigate(productUrl);
  };

  return (
    <header className="header-article-cta">
      <div className="header-article-cta__content">
        <div className="header-article-cta__text">
          Take the <strong>FREE Quiz</strong> to Get Your Career Freedom Plan
        </div>
        <button className="header-article-cta__cta" onClick={handleButtonClick}>
          Start the Quiz
        </button>
      </div>
    </header>
  );
};

export default HeaderArticleCTA;
