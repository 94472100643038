// src/components/Articles/Subheadline.js

import React from 'react';
import './Subheadline.css';

const Subheadline = ({ children }) => {
  return <h2 className="subheadline">{children}</h2>;
};

export default Subheadline;
