// src/components/Articles/Headline.js

import React from 'react';
import './Headline.css';

const Headline = ({ children }) => {
  return <h1 className="headline">{children}</h1>;
};

export default Headline;
