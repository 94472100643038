// src/components/List.js

import React from 'react';
import './List.css';

const List = ({ children }) => {
  return (
    <ul className="list">
      {children}
    </ul>
  );
};

export default List;
