import React from 'react';
import './LandingDisclaimer.css';

const LandingDisclaimer = () => {
    const currentYear = new Date().getFullYear();

  return (
    <div className="landing_disclaimer">
        <p className='landing_copyright'>{currentYear} © All rights reserved.</p>
        <p>DISCLAIMER: Results may vary from person to person. By continuing, you agree to with our Terms of Service, Privacy Policy, Money-Back Policy, Subscription terms, Cookie policy. Max Potential's content serves solely for educational purposes and is not a replacement for professional fitness or nutritional advice. Please consult a certified fitness trainer or nutritionist for personalized fitness and dietary decisions.</p>
    </div>
  );
};

export default LandingDisclaimer;
