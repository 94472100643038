// src/components/Li.js

import React from 'react';
import './Li.css';

const Li = ({ children }) => {
  return (
    <li className="list-item">
      {children}
    </li>
  );
};

export default Li;
