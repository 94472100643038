import React from 'react';
import './ThanksForSharingPage.css';
import { useNavigate } from 'react-router-dom';
import HeaderQuiz from '../components/HeaderQuiz/HeaderQuiz';
import { Link } from 'react-router-dom';

const ThanksForSharingPage = ({ questionNumber, totalQuestions, basePath }) => {
    const navigate = useNavigate();
    
    // Get gender from localStorage
    const selectedGender = localStorage.getItem('gender') || 'male';
    
    // Get fitness issues from localStorage
    const fitnessIssues = localStorage.getItem('fitnessIssues');
    let parsedFitnessIssues = [];
    try {
        parsedFitnessIssues = JSON.parse(fitnessIssues) || [];
    } catch (e) {
        console.error('Error parsing fitness issues:', e);
    }
    
    // Determine image path based on gender
    const imagePath = selectedGender === 'female' 
        ? '/assets/images/gender/promise-social-female.png'
        : '/assets/images/gender/promise-social-male.png';

    const getContent = () => {
        if (parsedFitnessIssues.includes('None of the above')) {
            return {
                headline: `Great mindset! 💪`,
                paragraph1: `You're already on the right track with your positive attitude.`,
                paragraph2: `Max Potential will help you take your fitness journey to the next level with a personalized plan that matches your experience and goals.`,
            };
        } else {
            return {
                headline: `You're not alone! 🤲`,
                paragraph1: `A lot of people hit the same roadblocks by starting too big too soon. 😔`,
                paragraph2: `Max Potential gives you a clear workout plan that's easy to stick to, keeps you motivated, and focuses on lasting lifestyle change—not just quick results.`,
            };
        }
    };

    const { headline, paragraph1, paragraph2 } = getContent();

    const handleContinue = () => {
        navigate(`${basePath}/question/${questionNumber + 1}`);
    };

    return (
        <div className="thanks-for-sharing-page">
            <div className="thanks-for-sharing-page-padding">
                <h1 className="thanks-for-sharing-page__headline">{headline}</h1>
                <img 
                    src={imagePath}
                    alt="Social support"
                    className="thanks-for-sharing-page__image"
                />

                <div className="thanks-for-sharing-page__paragraph">
                    <p>{paragraph1}</p>
                    <p style={{ marginTop: '1rem' }}>{paragraph2}</p>
                </div>
                <button 
                    className="thanks-for-sharing-page__continue" 
                    onClick={handleContinue}
                >
                    Continue
                </button>
                <Link 
                    to={`${basePath}/question/${questionNumber}`} 
                    className="thanks-for-sharing-page__go-back"
                >
                    ← Go Back
                </Link>
            </div>
        </div>
    );
};

export default ThanksForSharingPage;
