import { getCookie } from '../utils/cookies';

const callAPI = async (path, method, data) => {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}${path}`, {
    method: method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

  return {"data": await response.json(), "status": response.status};
};

const formatShippingField = (shipping) => {
  return !shipping ? null : {
    first_name: shipping.firstName,
    last_name: shipping.lastName,
    country_code: shipping.country,
    city: shipping.city,
    address1: shipping.address1,
    address2: shipping.address2,
    state: shipping.state,
    postal_code: shipping.zipCode,
  };
};

export const createUser = async () => {
  return await callAPI(
    "/api/v1/users/",
    "POST",
    {
      utm_source: getCookie("utm_source"),
      utm_medium: getCookie("utm_medium"),
      utm_campaign: getCookie("utm_campaign"),
      utm_term: getCookie("utm_term"),
      utm_content: getCookie("utm_content"),
      gcid: getCookie("_ga").slice(6),
      gclid: getCookie("gclid"),
      fbclid: getCookie("fbclid"),
      fbp: getCookie("_fbp"),
      fbc: getCookie("_fbc"),
      funnel_name: getCookie("funnel_name"),
      funnel_version: getCookie("funnel_version"),
    },
  );
};

export const upgradeUserToLead = async (id, email, gender, firstName, bookColor) => {
  return await callAPI(
    "/api/v1/users/upgrade-to-lead/",
    "POST",
    {
      id,
      email,
      gender,
      first_name: firstName,
      book_color: bookColor,
      utm_source: getCookie("utm_source"),
      utm_medium: getCookie("utm_medium"),
      utm_campaign: getCookie("utm_campaign"),
      utm_term: getCookie("utm_term"),
      utm_content: getCookie("utm_content"),
      gcid: getCookie("_ga").slice(6),
      gclid: getCookie("gclid"),
      fbclid: getCookie("fbclid"),
      fbp: getCookie("_fbp"),
      fbc: getCookie("_fbc"),
      funnel_name: getCookie("funnel_name"),
      funnel_version: getCookie("funnel_version"),
    },
  );
};

export const updateUserUTMs = async (userId) => {
  return await callAPI(
    `/api/v1/users/${userId}/`,
    "PATCH",
    {
      utm_source: getCookie("utm_source"),
      utm_medium: getCookie("utm_medium"),
      utm_campaign: getCookie("utm_campaign"),
      utm_term: getCookie("utm_term"),
      utm_content: getCookie("utm_content"),
      funnel_name: getCookie("funnel_name"),
      funnel_version: getCookie("funnel_version"),
    },
  );
};

export const saveUserQuizAnswers = async (userId, question, answers, funnelName, funnelVersion) => {
  return await callAPI(
    "/api/v1/users/save-quiz-answers/",
    "POST",
    {
      user_id: userId,
      question,
      answers,
      funnel_name: funnelName,
      funnel_version: funnelVersion,
    },
  );
};

export const createPaymentIntent = async (userId, productId, shippingAddress) => {
  return await callAPI(
    "/api/v1/payments/stripe/payment-intent/",
    "POST",
    { user_id: userId, product_id: productId, shipping: formatShippingField(shippingAddress) },
  );
};

export const capturePaymentIntent = async (externalId) => {
  return await callAPI(
    "/api/v1/payments/stripe/payment-intent/capture/",
    "POST",
    { external_id: externalId },
  );
};

export const createPaypalOrder = async (userId, productId, shippingAddress) => {
  return await callAPI(
    "/api/v1/payments/paypal/order/",
    "POST",
    { user_id: userId, product_id: productId, shipping: formatShippingField(shippingAddress) },
  );
};

export const capturePaypalOrder = async (externalId, externalOrderId) => {
  return await callAPI(
    "/api/v1/payments/paypal/order/capture/",
    "POST",
    { external_id: externalId, external_order_id: externalOrderId },
  );
};

export const createStripeSubscription = async (userId, planId, productId, shippingAddress) => {
  return await callAPI(
    "/api/v1/payments/stripe/subscription/",
    "POST",
    { user_id: userId, plan_id: planId, product_id: productId, shipping: formatShippingField(shippingAddress) },
  );
};

export const captureStripeSubscription = async (externalPaymentId) => {
  return await callAPI(
    "/api/v1/payments/stripe/subscription/capture/",
    "POST",
    { external_payment_id: externalPaymentId },
  );
};

export const createPaypalSubscription = async (userId, planId, productId, shippingAddress) => {
  return await callAPI(
    "/api/v1/payments/paypal/subscription/",
    "POST",
    { user_id: userId, plan_id: planId, product_id: productId, shipping: formatShippingField(shippingAddress) },
  );
};

export const capturePaypalSubscription = async (externalId, externalPaymentId) => {
  return await callAPI(
    "/api/v1/payments/paypal/subscription/capture/",
    "POST",
    { external_id: externalId, external_payment_id: externalPaymentId },
  );
};

export const upgradeStripeSubscription = async (subscriptionId, planId) => {
  return await callAPI(
    "/api/v1/payments/stripe/subscription/upgrade/",
    "POST",
    { id: subscriptionId, plan_id: planId },
  );
};

export const upgradePaypalSubscription = async (subscriptionId, planId) => {
  return await callAPI(
    "/api/v1/payments/paypal/subscription/upgrade/",
    "POST",
    { id: subscriptionId, plan_id: planId },
  );
};

export const captureUpgradePaypalSubscription = async (subscriptionId, externalId, externalPaymentId) => {
  return await callAPI(
    "/api/v1/payments/paypal/subscription/upgrade/capture/",
    "POST",
    { id: subscriptionId, external_id: externalId, external_payment_id: externalPaymentId },
  );
};
