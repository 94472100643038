// src/components/Articles/Image.js

import React from 'react';
import { Link } from 'react-router-dom';
import './Image.css';

const Image = ({ src, alt, to }) => {
  return (
    <Link to={to}>
      <img className="article-image" src={src} alt={alt} />
    </Link>
  );
};

export default Image;
