import React from 'react';
import Review from './Review';

const Reviews = () => {
  return (
    <div className='padding-16'>
        <Review 
          name="Emma S."
          description="I've tried every method to boost my income—gig apps, online surveys, you name it. None of it worked. So when I found the Careeristo Career Freedom Plan, I was skeptical. Let me say, this program turned me into a believer! Within the first month, I started earning extra money without feeling overwhelmed. This has been a game-changer for me!"
          authorImg='/assets/images/articles/reviews/review1-author.png'
          photoImg='/assets/images/articles/reviews/review1-photo.jpg' 
        />

        <Review 
          name="Daniel M."
          description="Careeristo has a fantastic approach. My financial stress has finally eased, and my income has significantly increased. I would readily endorse Careeristo. Deeply grateful!"
          authorImg='/assets/images/articles/reviews/review2-author.png'
          photoImg='/assets/images/articles/reviews/review2-photo.jpg' 
        />

        <Review 
          name="Lily W."
          description="I've tried numerous online courses, each promising to be the best. But it was Careeristo that truly helped me. It's user-friendly and just fantastic!"
          authorImg='/assets/images/articles/reviews/review3-author.png'
          photoImg='/assets/images/articles/reviews/review3-photo.jpg' 
        />

        <Review 
          name="Roger B."
          description="Careeristo has transformed my life. The financial worries that used to plague my days are now a memory. Within four days of using Careeristo, I started seeing results."
          authorImg='/assets/images/articles/reviews/review4-author.png'
          photoImg='/assets/images/articles/reviews/review4-photo.jpg' 
        />

        <Review 
          name="Maria J."
          description="Careeristo’s structure is just right—it's neither too complex nor too basic! The thought behind the personalized plan is impressive. My husband loved it so much, I'm getting another for him. Outstanding program!"
          authorImg='/assets/images/articles/reviews/review5-author.png'
          photoImg='/assets/images/articles/reviews/review5-photo.jpg' 
        />

        <Review 
          name="Tom L."
          description="Careeristo has been a massive help. Its unique selling point? The well-thought-out design that adapts perfectly to your learning style. Of all the programs I've tried, Careeristo is the one that brought real results. It's comprehensive yet easy to follow."
          authorImg='/assets/images/articles/reviews/review6-author.png'
          photoImg='/assets/images/articles/reviews/review6-photo.jpg' 
        />

        <Review 
          name="Isabel K."
          description="Careeristo is excellent! I started using it the day after I signed up, and I've been seeing extra income ever since. This has been the most financially comfortable week I've had in ages."
          authorImg='/assets/images/articles/reviews/review7-author.png'
          photoImg='/assets/images/articles/reviews/review7-photo.jpg' 
        />

        <Review 
          name="Vikram H."
          description="Careeristo has been a game-changer for me. It's perfectly structured and, unlike other rigid programs, it's comfortably flexible."
          authorImg='/assets/images/articles/reviews/review8-author.png'
          photoImg='/assets/images/articles/reviews/review8-photo.jpg' 
        />

        <Review 
          name="Sophie R."
          description="Careeristo is a perfect fit and doesn't feel overwhelming. It keeps me on track, providing much-needed guidance to improve my finances."
          authorImg='/assets/images/articles/reviews/review9-author.png'
          photoImg='/assets/images/articles/reviews/review9-photo.jpg' 
        />

    </div>
  );
};

export default Reviews;
