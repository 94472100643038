// src/components/Articles/Disclaimer.js

import React from 'react';
import './Disclaimer.css';

const Disclaimer = ({ children }) => {
  return <p className="disclaimer">{children}</p>;
};

export default Disclaimer;
