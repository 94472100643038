// src/components/Articles/Para.js

import React from 'react';
import './Para.css';

const Para = ({ children }) => {
  return <p className="para">{children}</p>;
};

export default Para;
