import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ completed, total }) => {
  const rawProgress = ((completed+0.3) / total);
  const progressPercentage = 100 * Math.pow(rawProgress, 0.57); // Apply exponential function (square root)

  return (
    <div className="progress-bar">
      <div
        className="progress-bar__completed"
        style={{ width: `${progressPercentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
