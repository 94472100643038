// src/components/Articles/AuthorBlock.js

import React from 'react';
import './AuthorBlock.css';

const AuthorBlock = ({ src, name }) => {
  return (
    <div className="author-block">
      <img src={src} alt="Author" />
      <p>{name}</p>
    </div>
  );
};

export default AuthorBlock;
