const questionsData = [

    {
        id: 'age',
        type: 'single',
        layout: 'two-columns',
        question: 'BUILD YOUR PERFECT BODY',
        description: 'According to your age and BMI',
        options: ['18-24', '25-34', '35-44', '45+'],
        optionImages: [
            '../assets/images/gender/male1.png',
            '../assets/images/gender/male2.png',
            '../assets/images/gender/male3.png',
            '../assets/images/gender/male4.png',
        ],
        optionImagesSize: '128px',
        transition: '',
        // image: '../assets/images/question-illustrations/q1-alt.png',
    },
    {
        type: 'single',
        // layout: 'two-columns',
        question: `What is your goal?`,
        description: 'Please select your first priority.',
        options: ['Lose Weight', 'Gain Muscle Mass', 'Get Fit'],
        optionImages: [
            '../../assets/images/gender/male-lose-weight.png',
            '../../assets/images/gender/male-gain-muscle-mass.png',
            // '../../assets/images/gender/male-get-shredded.png',
            '../../assets/images/gender/male-get-shredded.png',
        ],
        optionImagesSize: '100px',
    },
    {
        type: 'slider-with-options',
        question: "Choose your level of body fat",
        defaultValue: 3,
        options: [
            "5-9%",
            "10-14%",
            "15-19%",
            "20-24%",
            "30-34%",
            "35-39%",
            ">40%",
            // ... more options
        ],
        optionImages: [
            '../../assets/images/gender/fat-level/male-5.png',
            '../../assets/images/gender/fat-level/male-10.png',
            '../../assets/images/gender/fat-level/male-15.png',
            '../../assets/images/gender/fat-level/male-25.png',
            '../../assets/images/gender/fat-level/male-30.png',
            '../../assets/images/gender/fat-level/male-35.png',
            '../../assets/images/gender/fat-level/male-40.png',
            // ... more images
        ],
        optionImagesSize: "200px" // optional
    },
    {
        type: 'multi',
        question: 'Select problem areas',
        options: ['Weak chest', 'Beer belly', 'Slim arms', 'Slim legs'],
        optionImages: [
            '../../assets/images/gender/problem-areas/male-weak-chest.png',
            '../../assets/images/gender/problem-areas/male-beer-belly.png',
            '../../assets/images/gender/problem-areas/male-slim-arms.png',
            '../../assets/images/gender/problem-areas/male-slim-legs.png',
        ],
        optionImagesSize: '64px',
    },
    {
        id: 'diets',
        type: 'multi',
        question: 'Do you follow any of these diets?',
        description: '',
        options: [
            `Vegetarian`,
            `Vegan`,
            `Keto`,
            `Mediterranean`,
            `Pesketarian`,
            `None of the above`
        ],
        optionDescriptions: [
            'No meat',
            'No animal products at all',
            'Low carb, high fat',
            'Rich in plant-based foods',
            'No meat except fish and seafood',
            ''
        ],

        optionImages: [
            '../../assets/images/question-answers/broccoli.png',
            '../../assets/images/question-answers/seedling.png',
            '../../assets/images/question-answers/meat.png',
            '../../assets/images/question-answers/olive.png',
            '../../assets/images/question-answers/fish.png',
        ],
        optionImagesSize: '32px',
    },

    {
        type: 'single',
        question: `How often do you eat or drink sugary foods or beverages?`,
        description: '',
        options: ['Rarely', '3-5 times a week', 'Probably daily'],
        image: '../../assets/images/question-illustrations/sugar.png',
    },
    {
        type: 'single',
        question: 'How much water do you drink daily?',
        options: ['Only coffee or tea', 'Less than 2 glasses 💧', '2-6 glasses 💧💧', '7-10 glasses 💧💧💧', 'More than 10 glasses 🐳'],
        optionDescriptions: [
            '',
            'up to 0,5 l / 17 oz',
            '0,5-1,5 l / 17-50 oz',
            '1,5-2,5 l / 50-85 oz',
            'more than 2,5 l / 85 oz'
        ],
        optionImages: [
            '../../assets/images/question-answers/coffee-small.png',
            '../../assets/images/question-answers/water-1.png',
            '../../assets/images/question-answers/water-3.png',
            '../../assets/images/question-answers/water-7.png',
            '../../assets/images/question-answers/water-10.png',
        ],
        optionImagesSize: '64px',
    },
    {
        type: 'input-height',
        question: "What's your height?",
        // description: 'Enter your height'
    },
    {
        type: 'input-weight', // or 'input-height'
        question: 'What is your current weight?',
        description: 'Enter your weight below'
    },
    {
        type: 'input-weight', // or 'input-height'
        question: 'What is your desired weight?',
        description: 'Enter your desired weight below'
    },
    {
        type: 'slider-with-options',
        question: "What's your level of fitness?",
        defaultValue: 4,
        options: [
            "1 - Beginner",
            "2 - Beginner",
            "3 - Beginner",
            "4 - Amateur",
            "5 - Amateur",
            "6 - Amateur",
            "7 - Amateur",
            "8 - Pro",
            "9 - Pro",
            "10 - Pro",

            // ... more options
        ],
        optionDescriptions: [
            "😴 I find it hard to stand up from the floor without help.",
            "😓 Climbing a few stairs makes me breathe heavily.",
            "🚶 Walking is okay, but running is too hard for me.",
            "🏃 I exercise only a few times a year.",
            "🗓️ I try to work out once a week, but it's not regular yet.",
            "🏋️ I now exercise at least once a week regularly.",
            "💪 For the past few months, I've been working out twice a week.",
            "🔥 I'm dedicated to fitness and exercise whenever I can.",
            "🏆 I'm in great shape but aim to get even better.",
            "🚀 I'm in the best shape of my life and feel amazing."        
        ],
        optionImages: [
            '../../assets/images/question-answers/fitness-level/1.png',
            '../../assets/images/question-answers/fitness-level/2.png',
            '../../assets/images/question-answers/fitness-level/3.png',
            '../../assets/images/question-answers/fitness-level/4.png',
            '../../assets/images/question-answers/fitness-level/5.png',
            '../../assets/images/question-answers/fitness-level/6.png',
            '../../assets/images/question-answers/fitness-level/7.png',
            '../../assets/images/question-answers/fitness-level/8.png',
            '../../assets/images/question-answers/fitness-level/9.png',
            '../../assets/images/question-answers/fitness-level/10.png',
        ],
        optionImagesSize: "200px" // optional
    },
    {
        type: 'single',
        layout: 'cards',
        question: 'Like or Dislike?',
        description: 'Pull-ups',
        options: ['Dislike', 'Neutral', 'Like'],
        image: '../../assets/images/question-illustrations/pullups.png',
        optionImages: [
            '../../assets/images/question-answers/thumbs-down.png',
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/thumbs-up.png',
        ],
        optionImagesSize: '32px'
    },
    {
        type: 'single',
        layout: 'cards',
        question: 'Like or Dislike?',
        description: 'Squats with weights',
        options: ['Dislike', 'Neutral', 'Like'],
        image: '../../assets/images/question-illustrations/squatswithdumbbells.png',
        optionImages: [
            '../../assets/images/question-answers/thumbs-down.png',
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/thumbs-up.png',
        ],
        optionImagesSize: '32px'
    },
    {
        type: 'single',
        layout: 'cards',
        question: 'Like or Dislike?',
        description: 'Push-ups',
        options: ['Dislike', 'Neutral', 'Like'],
        image: '../../assets/images/question-illustrations/pushups.png',
        optionImages: [
            '../../assets/images/question-answers/thumbs-down.png',
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/thumbs-up.png',
        ],
        optionImagesSize: '32px'
    },
    {
        type: 'single',
        layout: 'cards',
        question: 'Like or Dislike?',
        description: 'Bar dips',
        options: ['Dislike', 'Neutral', 'Like'],
        image: '../../assets/images/question-illustrations/bardips.png',
        optionImages: [
            '../../assets/images/question-answers/thumbs-down.png',
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/thumbs-up.png',
        ],
        optionImagesSize: '32px'
    },
    {
        type: 'single',
        layout: 'cards',
        question: 'Like or Dislike?',
        description: 'Cardio',
        options: ['Dislike', 'Neutral', 'Like'],
        image: '../../assets/images/question-illustrations/cardio.png',
        optionImages: [
            '../../assets/images/question-answers/thumbs-down.png',
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/thumbs-up.png',
        ],
        optionImagesSize: '32px'
    },
    {
        type: 'multi',
        question: 'What sports are you interested in?',
        options: [`Gym Workouts`, 'Workouts at home', 'Boxing', 'Other martial arts', 'Jogging', 'None of the above'],
        optionImages: [
            '../../assets/images/question-answers/dumbbell.png',
            '../../assets/images/question-answers/house.png',
            '../../assets/images/question-answers/boxing-glove.png',
            '../../assets/images/question-answers/martial-arts.png',
            '../../assets/images/question-answers/running.png',
            // '../../assets/images/question-answers/cross-mark.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'multi',
        question: 'Have you experienced any of these issues in your previous fitness attempts?',
        options: [`Lack of motivation`, `Made many attempts but saw no progress`, `Felt insecure at the gym`, `Didn't have a clear plan`, `My workouts were too hard`, `Bad coaching`, `None of the above`],
        optionImages: [
            '../../assets/images/question-answers/sleeping-face.png',
            '../../assets/images/question-answers/chart-decreasing.png',
            '../../assets/images/question-answers/fearful-face.png',
            '../../assets/images/question-answers/thinking-face.png',
            '../../assets/images/question-answers/dizzy-face.png',
            '../../assets/images/question-answers/thumbs-down.png',
            // '../../assets/images/question-answers/cross-mark.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'multi',
        question: 'Tick your additional goals below',
        description: `We're sure you want not only a better body but to improve your lifestyle too.`,
        options: ['Improve sleep', 'Form a physical habit',
            'Self-discipline',
            'Feel healthier',
            'Reduce Stress',
            'Look more attractive',
            'None of the above'],
        optionImages: [
            '../../assets/images/question-answers/sleeping-face.png',
            '../../assets/images/question-answers/bicep.png',
            '../../assets/images/question-answers/lotus.png',
            '../../assets/images/question-answers/seedling.png',
            '../../assets/images/question-answers/fearful-face.png',
            '../../assets/images/question-answers/smiling.png',
        ],
        optionImagesSize: '32px',

    },

    {
        type: 'multi',
        question: 'Do you have any of the following conditions?',
        description: `We care about your health and want to make sure you're safe.`,
        options: ['Heart disease',
                    'Severe scoliosis',
                    'Spinal damage',
                    'Benign or malignant tumor',
                    'Hypertension', 'None of the above'],
        prompt: {
            message: "If you have any health conditions, consult your physician before starting to follow Max Potential program",
            buttonText: "Got It"
        }
    },

    {
        type: 'single',
        question: `How many push-ups can you do in one round?`,
        options: ['Less than 10', `10 to 20`, `21 to 30`, `More than 30`],
        optionImages: [
            '../../assets/images/question-answers/bicep.png',
            '../../assets/images/question-answers/glowing-star.png',
            '../../assets/images/question-answers/fire.png',
            '../../assets/images/question-answers/trophy.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'single',
        question: `How many pull-ups can you do in one round?`,
        options: [`I can't do a single pull-up`, `Less than 5`, `5 to 10`, `More than 10`],
        optionImages: [
            '../../assets/images/question-answers/bicep.png',
            '../../assets/images/question-answers/glowing-star.png',
            '../../assets/images/question-answers/fire.png',
            '../../assets/images/question-answers/trophy.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'single',
        question: `Where will you do the workouts?`,
        options: [`Home`, `Gym`, `Both`],
        optionImages: [
            '../../assets/images/question-answers/house.png',
            '../../assets/images/question-answers/dumbbell.png',
            '../../assets/images/question-answers/arrows-circle.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'single',
        question: `Which equipment do you have access to?`,
        options: [`No equipment`, `Basic equipment`, `Full equipment`],
        optionDescriptions: [
            'Just your bodyweight',
            'Dumbbells, kettlebells, resistance bands, etc.',
            'Resistance band, dumbbells, parallel and horizontal bars'
        ],
        optionImages: [
            '../../assets/images/question-answers/man-running.png',
            '../../assets/images/question-answers/dumbbell.png',
            '../../assets/images/question-answers/gear.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'single',
        question: `How many times per week have you trained in the last 3 months?`,
        options: [`Not at all`, `1-2 times a week`, `3 times a week`, `More than 3 times a week`],
        optionDescriptions: [
            `I haven't trained, but I will after claiming my workout program!`,
            ``, ``
        ],
        optionImages: [
            '../../assets/images/question-answers/pensive.png',
            '../../assets/images/question-answers/bicep.png',
            '../../assets/images/question-answers/dumbbell.png',
            '../../assets/images/question-answers/trophy.png',
        ],
        optionImagesSize: '32px',
        prompt: {
            messages: {
                'Not at all': "Starting from zero is okay! We'll begin with basic exercises and gradually increase intensity as you build strength and endurance.",
                '1-2 times a week': "Great start! We'll help you establish a more consistent routine to achieve better results.",
                '3 times a week': "Excellent commitment! We'll optimize your workout plan to help you reach the next level.",
                'More than 3 times a week': "Impressive dedication! We'll ensure your program maintains this intensity while preventing overtraining."
            },
            buttonText: "Continue"
        }
    },
    {
        type: 'single',
        question: 'How much time are you ready to spend on a workout?',
        options: ['15-20 min', '30-45 min', '1 hour', 'Let Max Potential decide'],
        optionImages: [
            '../../assets/images/question-answers/clock.png',
            '../../assets/images/question-answers/clock.png',
            '../../assets/images/question-answers/clock.png',
            '../../assets/images/question-answers/clock.png',
        ],
        optionImagesSize: '32px',
    },
    {
        type: 'single',
        question: 'How much time are you ready to spend per meal prep?',
        description: 'We will suggest more recipes within your preferred prep time.',
        options: ['Less than 30 min', '30-45 min', '45+ min', 'I prefer to order meals or eat out'],
        optionImages: [
            '../../assets/images/question-answers/clock.png',
            '../../assets/images/question-answers/clock.png',
            '../../assets/images/question-answers/clock.png',
            '../../assets/images/question-answers/box.png',
        ],
        optionImagesSize: '48px',
    },
    {
        type: 'multi-section',
        question: 'What ingredients should we add to your personalized meal plans?',
        sections: [
            {
                title: 'Veggies',
                icon: '../../assets/images/question-answers/broccoli.png',
                options: [
                    '🥦 Broccoli',
                    '🌱 Spinach',
                    '🍅 Tomato',
                    '🥕 Carrot',
                    '🥒 Cucumber',
                    '🫑 Bell pepper',
                    '🍄 Mushrooms',
                    '🥬 Lettuce',
                    '🌽 Corn'
                ]
            },
            {
                title: 'Fiber & Grains',
                icon: '../../assets/images/question-answers/grain.png',
                options: [
                    '🍚 Brown rice',
                    '🍠 Sweet potatoes',
                    '🍞 Whole grain bread',
                    '🥣 Quinoa',
                    '🌾 Oats',
                    ]
            },
            {
                title: 'Protein',
                icon: '../../assets/images/question-answers/meat.png',
                options: [
                    '🍗 Chicken breast',
                    '🥩 Lean beef',
                    '🦃 Turkey breast',
                    '🥚 Eggs',
                    '🐖 Pork tenderloin',
                    '🌱 Lentils',
                    '🍛 Chickpeas'
                    ]
            },
            {
                title: 'Seafood',
                icon: '../../assets/images/question-answers/fish.png',
                options: [
                    '🐟 Salmon',
                    '🐟 Canned tuna',
                    '🦐 Shrimp',
                    '🐟 Cod',
                    '🐟 Tilapia'
                    ]
            },
            {
                title: 'Dairy & Alternatives',
                icon: '../../assets/images/question-answers/milk.png',
                options: [
                    '🥛 Low-fat milk',
                    '🥛 Greek yogurt',
                    '🧀 Cottage cheese',
                    '🧀 Low-fat mozzarella',
                    '🧀 Cheddar cheese',
                    '🧀 Cream cheese',
                    '🍶 Tofu',
                    '🥛 Unsweetened almond milk'
                ]
            },
            {
                title: 'Fruits',
                icon: '../../assets/images/question-answers/fruits.png',
                options: [
                    '🥑 Avocadoes',
                    '🍌 Bananas',
                    '🍎 Apples',
                    '🍓 Berries',
                    '🍊 Oranges',
                    '🍑 Peaches',
                    '🍍 Pineapple',
                    '🍇 Grapes'
                    ]
            }
        ]
    },
    {
        type: 'input-text',
        question: "What should we call you?",
        description: `Let's create the cover of your book.`
    },
    {
        type: 'book-color',
        question: "Choose the design of your book",
        description: "Select a color that matches your style",
        options: [
            "#038CCE", 
            "#9900FF", 
            "#03CE2C", 
            "#F5009F",
            "#F50004", 
            "#FE6E3E", 
            "#FEEE3E", 
        ],
        optionImages: [
            '../../assets/images/question-answers/books/book-blue.jpg',
            '../../assets/images/question-answers/books/book-purple.jpg',
            '../../assets/images/question-answers/books/book-green.jpg',
            '../../assets/images/question-answers/books/book-pink.jpg',
            '../../assets/images/question-answers/books/book-red.jpg',
            '../../assets/images/question-answers/books/book-orange.jpg',
            '../../assets/images/question-answers/books/book-yellow.jpg',
        ],
        optionImagesSize: "300px"
    },
];

export default questionsData;
