import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { saveUserQuizAnswers } from '../../../api/api';

import './HeroSectionNew.css';

const HeroSectionNew = ({ setSelectedGender, basePath, funnelName, funnelVersion }) => {
  const navigate = useNavigate();
  const [showFemalePrompt, setShowFemalePrompt] = useState(false);
  const [showCopyFeedback, setShowCopyFeedback] = useState(false);

  const trackSelectGender = (gender) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'dl_select_gender',
        gender,
      },
    });
  };

  const handleClick = (gender, e) => {
    e.stopPropagation();
    
    if (gender === 'female') {
      setShowFemalePrompt(true);
      return;
    }

    trackSelectGender(gender);
    setSelectedGender(gender);
    localStorage.setItem('gender', gender);

    const userId = localStorage.getItem("userId");
    if (userId) {
      saveUserQuizAnswers(userId, "Start by selecting your gender", [gender], funnelName, funnelVersion);
    }

    navigate(`${basePath}/question/1`);
  };

  const handleShare = async () => {
    const shareUrl = window.location.href;
    
    try {
      await navigator.clipboard.writeText(shareUrl);
      setShowCopyFeedback(true);
      setTimeout(() => setShowCopyFeedback(false), 2000);
    } catch (error) {
      console.log('Error copying to clipboard:', error);
    }
  };

  const handleClickOutside = (e) => {
    if (!e.target.closest('.female-prompt') && !e.target.closest('.female-card')) {
      setShowFemalePrompt(false);
    }
  };

  const handleClosePrompt = (e) => {
    e.stopPropagation();
    setShowFemalePrompt(false);
  };

  useEffect(() => {
    if (showFemalePrompt) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showFemalePrompt]);

  return (
    <div className="hero-container">
      <img className='hero-book-img' fetchpriority="high" src="../assets/images/personalized-book-transparent.gif" alt="Personalized Book" />
      <h2 className="hero-title">Achieve Your Dream Body</h2>
      <p className="hero-subtitle">Create the perfect book for your goals - start by selecting your gender.</p>
      <div className="hero-quiz-invite">1-MINUTE QUIZ</div>
      <div className="hero-gender-selection">
        <div 
          className="gender-card male-card" 
          onClick={(e) => handleClick('male', e)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleClick('male', e)}
          aria-label="Select male gender"
        >
          <div className="gender-button male-button">Male</div>
        </div>
        <div 
          className="gender-card female-card" 
          onClick={(e) => handleClick('female', e)}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && handleClick('female', e)}
          aria-label="Select female gender"
        >
          {showFemalePrompt && (
            <div className="female-prompt-overlay">
              <div className="female-prompt" onClick={handleClosePrompt}>
                <button 
                  className="close-button"
                  onClick={handleClosePrompt}
                  aria-label="Close prompt"
                >
                  ✕
                </button>
                <h3 className="prompt-title">Sorry, Ladies –<br/>Max Potential is for Men!</h3>
                <p className="prompt-message">
                  We're focused on helping men achieve their peak fitness with personalized workout plans. 
                  But don't worry – you probably know a guy who could use this! 💪
                </p>
                <p className="prompt-share-text">
                  Share this with your husband, boyfriend, brother, or best friend, and help them unlock their Max Potential!
                </p>
                <button 
                  className="share-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShare();
                  }}
                  aria-label="Copy link to share"
                >
                  {showCopyFeedback ? 'Link Copied! ✓' : 'Copy Link to Share'}
                </button>
              </div>
            </div>
          )}
          <div className="gender-button female-button">Female</div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionNew;
