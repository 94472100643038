// src/components/Articles/Review.js

import React from 'react';
import './Review.css';

const Review = ({ name, description, authorImg, photoImg }) => {
  return (
    <div className="review">
      <img src={authorImg} alt={name} />
      <div className='testimonial__header'>
      <h4>{name}</h4>
      <div className="testimonial__verified">
          <img src="/assets/images/checkmark.svg" alt="Checkmark" />
          <span>Verified</span>
      </div>
      <div className="testimonial__stars">
            <img className="star-icon" src="/assets/images/star.svg" alt="Star" />
            <img className="star-icon" src="/assets/images/star.svg" alt="Star" />
            <img className="star-icon" src="/assets/images/star.svg" alt="Star" />
            <img className="star-icon" src="/assets/images/star.svg" alt="Star" />
            <img className="star-icon" src="/assets/images/star.svg" alt="Star" />
      </div>
      </div>

      <p className="description">{description}</p>
      {/* <img className='review-photo' src={photoImg} /> */}
    </div>
  );
};

export default Review;
